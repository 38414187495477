import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../../config';

export const checkEmailE = createAsyncThunk(
  'email/check',
  async ({email,siteId} ,{ rejectWithValue }) => {
    try {
      // Construire l'URL en fonction du rôle de l'utilisateur
      let url = `/emailCheck/emailLeak?email=${email}`;
      if ( siteId) {
        // If the user is a Client, siteId is required to be added
        url += `&siteId=${siteId}`;
      }
      const res = await Axios.get(url);
      return res.data;
    } catch (error) {
      // Utilisez rejectWithValue pour renvoyer l'erreur
      return rejectWithValue(error.response.data);
    }
  });

export const deleteEmailE = createAsyncThunk(
  'email/delete',
  async ({email,siteId} ,{ rejectWithValue }) => {
    try {
      const res = await Axios.delete(`/emailCheck/deleteEmail?email=${email}&siteId=${siteId}`);
      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      // We got validation errors, let's return those so we can reference in our component
      return rejectWithValue(error.response.data);
    }
  }
);
export const getEmailE = createAsyncThunk(
  'email/get',
  async (siteId, { rejectWithValue }) => {
    try {
      let url = `/emailCheck/testedEmailss`;

      if (siteId) {
        // If the user is a Client, siteId is required to be added
        url += `?siteId=${siteId}`;
      }
      const res = await Axios.get(url);
      return res.data;
    } catch (error) {
      // Utilisez rejectWithValue pour renvoyer l'erreur
      return rejectWithValue(error.response.data);
    }
  }
);





const  initialState= {
  isSuccess: false,
  isError: false,
  isFetching: false,
  isLeakedEmail: '',
  isLeakedDomain: false,
  isLeakedEmailList: false,
  messageError: '',
  emailList:[],
  email: [],
  emailLists:[],
  emailDelete:null,
  isSaving: false,
  saveError: null,
  isLeaked: null,
  loadingEmail: false,
  error: null,
  message: null,
}


const emailCheckSlice = createSlice({
  name: 'email',
  initialState,

  reducers: { clearEmailData:  () => initialState},
  extraReducers: (builder) => {
    builder
      .addCase(checkEmailE.pending, (state) => {
        // state.isFetching = true;
        state.isError = false;
        state.isSuccess = false;
        // state.isLeakedEmail=false 
        state.loadingEmail=true  
         })
      .addCase(checkEmailE.fulfilled, (state, { payload }) => {
        state.loadingEmail = false;
        state.isLeaked = payload.isLeaked;
        state.isSuccess = true;
        state.isError = false;
        // console.log('ffff',payload)
      })
      .addCase(checkEmailE.rejected, (state, { payload }) => {
        console.log(payload)
        state.loadingEmail = false;
        state.isError = true;
        state.isSuccess = false;
        state.messageError = payload.error;
      }) 
      .addCase(deleteEmailE.pending, (state) => {
        // state.isFetching = true;
        state.isError = false;
        state.isSuccess = false;
        // state.isLeakedEmail=false 
        state.loadingEmail=true  
         })
      .addCase(deleteEmailE.fulfilled, (state, { payload }) => {
        state.loadingEmail = false;
        state.emailDelete = payload;
        state.isSuccess = true;
        state.isError = false;
        // console.log('ffff',payload)
      })
      .addCase(deleteEmailE.rejected, (state, { payload }) => {
        console.log(payload)
        state.loadingEmail = false;
        state.isError = true;
        state.isSuccess = false;
        state.messageError = payload.error;
      }) 
    
      
      .addCase(getEmailE.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
        state.isSuccess = false;
         })
      .addCase(getEmailE.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.emailLists = payload;
        state.isSuccess = true;
        state.isError = false;
        // console.log('ffff',payload)
      })
      .addCase(getEmailE.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.messageError = payload.message;
      }) 
      
    
   
  },
});
export const { clearEmailData } = emailCheckSlice.actions;



export default emailCheckSlice.reducer;